// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-algemenevoorwaarden-js": () => import("./../../../src/templates/algemenevoorwaarden.js" /* webpackChunkName: "component---src-templates-algemenevoorwaarden-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-hoofdspecialiteiten-js": () => import("./../../../src/templates/hoofdspecialiteiten.js" /* webpackChunkName: "component---src-templates-hoofdspecialiteiten-js" */),
  "component---src-templates-marktendetails-js": () => import("./../../../src/templates/marktendetails.js" /* webpackChunkName: "component---src-templates-marktendetails-js" */),
  "component---src-templates-marktendetailssecond-js": () => import("./../../../src/templates/marktendetailssecond.js" /* webpackChunkName: "component---src-templates-marktendetailssecond-js" */),
  "component---src-templates-nieuws-js": () => import("./../../../src/templates/nieuws.js" /* webpackChunkName: "component---src-templates-nieuws-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-privacypolicy-js": () => import("./../../../src/templates/privacypolicy.js" /* webpackChunkName: "component---src-templates-privacypolicy-js" */),
  "component---src-templates-project-detail-js": () => import("./../../../src/templates/project-detail.js" /* webpackChunkName: "component---src-templates-project-detail-js" */),
  "component---src-templates-serviceafspraak-js": () => import("./../../../src/templates/serviceafspraak.js" /* webpackChunkName: "component---src-templates-serviceafspraak-js" */),
  "component---src-templates-servicedetail-js": () => import("./../../../src/templates/servicedetail.js" /* webpackChunkName: "component---src-templates-servicedetail-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-spareparts-js": () => import("./../../../src/templates/spareparts.js" /* webpackChunkName: "component---src-templates-spareparts-js" */),
  "component---src-templates-specialiteiten-js": () => import("./../../../src/templates/specialiteiten.js" /* webpackChunkName: "component---src-templates-specialiteiten-js" */),
  "component---src-templates-vacatures-js": () => import("./../../../src/templates/vacatures.js" /* webpackChunkName: "component---src-templates-vacatures-js" */),
  "component---src-templates-vacaturesoverzicht-js": () => import("./../../../src/templates/vacaturesoverzicht.js" /* webpackChunkName: "component---src-templates-vacaturesoverzicht-js" */)
}

